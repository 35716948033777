import React from "react";
import Page from "./Page";

const TimelinePage = () => {
  return (
    <>
      <Page pageTitle="Timeline Page" />
    </>
  );
};

export default TimelinePage;
